import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conflict',
  templateUrl: './conflict.component.html',
  styleUrls: ['./conflict.component.sass']
})
export class ConflictComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
