import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cultural-trends',
  templateUrl: './cultural-trends.component.html',
  styleUrls: ['./cultural-trends.component.sass']
})
export class CulturalTrendsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
